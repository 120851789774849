exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3mbBwvnAQeQ22ahmQXQtAK, ._2imK2caNy9jHJ43FnwZsDh {\n  color: #757575; }\n\n._2imK2caNy9jHJ43FnwZsDh:hover {\n  color: #4f5051; }\n\n._2fOsfPwuUrRLi2dfE65rtE {\n  color: #D50000; }\n  ._2fOsfPwuUrRLi2dfE65rtE:hover {\n    color: #a41701; }\n\n.Vw_h9AxUrnRYZkUZEIHel {\n  color: #CE4A00; }\n  .Vw_h9AxUrnRYZkUZEIHel:hover {\n    color: #9b4400; }\n\n._3mJZ8_4zeMRakkHm4-5_Q0 {\n  color: #2E7D32; }\n  ._3mJZ8_4zeMRakkHm4-5_Q0:hover {\n    color: #225528; }\n", ""]);

// exports
exports.locals = {
	"greyIconBase": "_3mbBwvnAQeQ22ahmQXQtAK",
	"icon": "_2imK2caNy9jHJ43FnwZsDh",
	"stopIconFlag": "_2fOsfPwuUrRLi2dfE65rtE",
	"yellowFlagIcon": "Vw_h9AxUrnRYZkUZEIHel",
	"greenFlagIcon": "_3mJZ8_4zeMRakkHm4-5_Q0"
};