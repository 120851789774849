exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".P6CBz2BVrNHm9s3P7otKR {\n  content: \"\";\n  border-left: 1px dotted #7e8285;\n  margin: 0 20px;\n  height: 35px; }\n", ""]);

// exports
exports.locals = {
	"header": "P6CBz2BVrNHm9s3P7otKR"
};