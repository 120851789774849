exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".D1TR8Fxu38J7wyv1yq-j5 {\n  overflow: hidden;\n  border: 3px dashed #99D0E9;\n  border-radius: 4px;\n  background-color: #f5fafa; }\n", ""]);

// exports
exports.locals = {
	"dashed": "D1TR8Fxu38J7wyv1yq-j5"
};