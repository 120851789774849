exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2eNbtK_34MGok6H8j54gKg {\n  display: flex;\n  flex: 1 1 auto;\n  outline: none;\n  overflow: hidden; }\n\n._3rsPIPoUmQWAt0G3Lyc1cw {\n  overflow: auto !important;\n  height: 100% !important; }\n  ._3rsPIPoUmQWAt0G3Lyc1cw::-webkit-scrollbar {\n    width: 9px;\n    border-radius: 10px; }\n  ._3rsPIPoUmQWAt0G3Lyc1cw::-webkit-scrollbar-track {\n    background-color: #3A4041;\n    border-radius: 10px;\n    margin: 10px; }\n  ._3rsPIPoUmQWAt0G3Lyc1cw::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background-color: #A1ACB0; }\n", ""]);

// exports
exports.locals = {
	"navContainer": "_2eNbtK_34MGok6H8j54gKg",
	"navScroll": "_3rsPIPoUmQWAt0G3Lyc1cw"
};