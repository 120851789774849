import makeActionCreator from 'utils/makeActionCreator'

export const types = {
  UPLOAD_DOCUMENTS_START: 'UPLOAD_DOCUMENTS_START',
  UPLOAD_ONE_DOC_COMPLETE: 'UPLOAD_ONE_DOC_COMPLETE',
  UPLOAD_DOCUMENTS_FINISH_WITH_FAILS: 'UPLOAD_DOCUMENTS_FINISH_WITH_FAILS',
  UPLOAD_DOCUMENTS_FINISH_SUCCESS: 'UPLOAD_DOCUMENTS_FINISH_SUCCESS',
  ACKNOWLEDGE_UPLOAD_FAILURES: 'ACKNOWLEDGE_UPLOAD_FAILURES',
  VERIFY_RETURN_DUPLICATE_FILES: 'VERIFY_RETURN_DUPLICATE_FILES',
  ADD_SELECTED_DOCS: 'ADD_SELECTED_DOCS',
  UPDATE_DOC_PROPERTY: 'UPDATE_DOC_PROPERTY',
  CLEAR_SELECTED_FILES: 'CLEAR_SELECTED_FILES',
  REMOVE_DOC: 'REMOVE_DOC',
  REMOVE_DUPLICATE: 'REMOVE_DUPLICATE',
  CLOSE_ALERT: 'CLOSE_ALERT',
  OPEN_ALERT: 'OPEN_ALERT',
  EXTRACT_INFO_REQUEST: 'EXTRACT_INFO_REQUEST',
  EXTRACT_INFO_SUCCESS: 'EXTRACT_INFO_SUCCESS',
  EXTRACT_INFO_FAIL: 'EXTRACT_INFO_FAIL',
  SET_INFO_REQUEST_IN_PROGRESS: 'SET_INFO_REQUEST_IN_PROGRESS',
  REJECT_NO_PROJECT_SELECTED: 'REJECT_NO_PROJECT_SELECTED',
  RESET_FAILED_UPLOAD_VALIDATION: 'RESET_FAILED_UPLOAD_VALIDATION',
  REJECT_EMPTY_JURISDICTIONS: 'REJECT_EMPTY_JURISDICTIONS',
  TOGGLE_ROW_EDIT_MODE: 'TOGGLE_ROW_EDIT_MODE',
  SEARCH_ROW_SUGGESTIONS_SUCCESS_JURISDICTION: 'SEARCH_ROW_SUGGESTIONS_SUCCESS_JURISDICTION',
  CLEAR_ROW_JURISDICTION_SUGGESTIONS: 'CLEAR_ROW_JURISDICTION_SUGGESTIONS',
  EXTRACT_INFO_SUCCESS_NO_DOCS: 'EXTRACT_INFO_SUCCESS_NO_DOCS',
  MERGE_INFO_WITH_DOCS: 'MERGE_INFO_WITH_DOCS',
  INVALID_FILES_FOUND: 'INVALID_FILES_FOUND',
  FLUSH_STATE: 'FLUSH_STATE'
}

export default {
  uploadDocumentsStart: makeActionCreator(types.UPLOAD_DOCUMENTS_START, 'selectedDocs', 'project', 'jurisdiction'),
  acknowledgeUploadFailures: makeActionCreator(types.ACKNOWLEDGE_UPLOAD_FAILURES),
  updateDocumentProperty: makeActionCreator(types.UPDATE_DOC_PROPERTY, 'index', 'property', 'value'),
  addSelectedDocs: makeActionCreator(types.ADD_SELECTED_DOCS, 'selectedDocs'),
  clearSelectedFiles: makeActionCreator(types.CLEAR_SELECTED_FILES),
  removeDoc: makeActionCreator(types.REMOVE_DOC, 'index'),
  closeAlert: makeActionCreator(types.CLOSE_ALERT,'alertType'),
  openAlert: makeActionCreator(types.OPEN_ALERT, 'text', 'title', 'alertType'),
  removeDuplicate: makeActionCreator(types.REMOVE_DUPLICATE, 'index', 'fileName'),
  extractInfoRequest: makeActionCreator(types.EXTRACT_INFO_REQUEST, 'infoSheetFormData', 'infoSheet'),
  resetFailedUploadValidation: makeActionCreator(types.RESET_FAILED_UPLOAD_VALIDATION),
  toggleRowEditMode: makeActionCreator(types.TOGGLE_ROW_EDIT_MODE, 'index', 'property'),
  clearRowJurisdictionSuggestions: makeActionCreator(types.CLEAR_ROW_JURISDICTION_SUGGESTIONS, 'index'),
  mergeInfoWithDocs: makeActionCreator(types.MERGE_INFO_WITH_DOCS, 'docs'),
  setInfoRequestProgress: makeActionCreator(types.SET_INFO_REQUEST_IN_PROGRESS)
}

