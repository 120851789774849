exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".uwYxAPiSUicHPUqGAriKd {\n  animation-duration: 10s;\n  animation-name: _3_r1khoDd0JaZPe4gwN655;\n  animation-delay: 1s;\n  animation-iteration-count: infinite;\n  width: 100%;\n  height: 200px;\n  padding-bottom: 50px;\n  color: #707070; }\n\n@keyframes _3_r1khoDd0JaZPe4gwN655 {\n  0% {\n    transform: translateX(0%); }\n  80% {\n    color: #779ecb; }\n  100% {\n    transform: translateX(100%); } }\n", ""]);

// exports
exports.locals = {
	"ghost": "uwYxAPiSUicHPUqGAriKd",
	"slidein": "_3_r1khoDd0JaZPe4gwN655"
};